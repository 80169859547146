import React from "react"
import styled from "styled-components"
import { Row, Col } from "react-grid-system"
import { Display, Lead } from "./typography"
import titleBorder from "../images/title-border.svg"
import scroll from "../images/scroll.png"
import Img from "gatsby-image"
import Nav from "./nav"
import { Box, Image, Container } from "@theme-ui/components"
import DesignTokens from "./designTokens"

const HeroHeader = ({
  title,
  subtitle,
  heroImage,
  highlight,
  logo,
  big,
  inDepth,
  simple,
}) => (
  <Wrapper highlight={highlight} big={big}>
    <CustomBackground>
      <Img fluid={heroImage.fluid} />
      <Gradient highlight={highlight} />
    </CustomBackground>
    <Container sx={{ maxWidth: "1280px" }}>
      <Row>
        <Col>
          <StyledBox>
            {logo ? (
              <img src={logo.url} />
            ) : (
              <Display as="h1" color="white">
                <Box sx={{ hyphens: ["auto", "auto", "manual"] }}>{title}</Box>
              </Display>
            )}
            {subtitle && (
              <CustomLead as="h2" color="white">
                {subtitle}
              </CustomLead>
            )}
          </StyledBox>
        </Col>
      </Row>
    </Container>
    <Nav />
    {big && (
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          bottom: 80,
          left: 0,
          textAlign: "center",
        }}
      >
        <Image sx={{ width: "55px" }} src={scroll} />
      </Box>
    )}
  </Wrapper>
)
const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  background-color: ${DesignTokens.colors.primary[500]};
  padding-top: ${props => (props.highlight || props.big ? `24rem` : `20rem`)};
  padding-bottom: ${props =>
    props.highlight || props.big ? `18rem` : `10rem`};
  @media screen and (max-width: 768px) {
    padding-top: ${props => (props.highlight || props.big ? `16rem` : `10rem`)};
    padding-bottom: ${props =>
      props.highlight || props.big ? `10rem` : `4rem`};
  }
`
const CustomBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  .gatsby-image-wrapper {
    height: 100%;
  }
`

const CustomLead = styled(Lead)`
  text-transform: ${props => (props.big ? "none" : "uppercase")};
  font-size: 24px;
`

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: ${props =>
    props.highlight
      ? `linear-gradient(to left, transparent 0 30%, #00793d00 90%),
    #00000030;`
      : `linear-gradient(to left, transparent 0 30%, #00793d50 60%),
    #00000060;`};
`

const StyledBox = styled.div`
  padding-top: 3rem;
  background-image: url(${titleBorder});
  background-repeat: no-repeat;
  @media screen and (max-width: 1280px) {
    width: 100%;
  }
  & > * {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default HeroHeader
